"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchLocation = void 0;

var _uxTreadGeoloc = require("@nike/ux-tread-geoloc");

// @ts-expect-error: no types yet in the library package

/**
 * @name fetchLocation
 * @description returns country from geoloc
 * @returns {string}
 */
var fetchLocation = function fetchLocation() {
  return (_uxTreadGeoloc.readGeolocCookie === null || _uxTreadGeoloc.readGeolocCookie === void 0 ? void 0 : (0, _uxTreadGeoloc.readGeolocCookie)().country) || '';
};

exports.fetchLocation = fetchLocation;