import { camelizeKeys, decamelizeKeys } from 'humps';
import { CONSENT_TYPE, COOKIE_PURPOSE_LIMITATION_NAMES, SERVER_PURPOSE_LIMITATION_NAMES, } from '@nike/privacy-shared-logic';
import getPurposeLimitationsMemberState from '../store/selectors/getPurposeLimitationsMemberState';
import getPurposeLimitationsGuestState from '../store/selectors/getPurposeLimitationsGuestState';
import * as API from '../API';
import { store } from '../store';
export const getPurposeLimitationByName = (purposeLimitations, name) => purposeLimitations.find(({ category }) => category === name);
export const getLevelsFromLimitations = (purposeLimitations) => purposeLimitations.reduce((accumulator, currentValue) => ({
    ...accumulator,
    [currentValue.category]: currentValue.level,
}), {});
const removeLevelField = (limitation) => {
    const limitationCopy = JSON.parse(JSON.stringify(limitation));
    delete limitationCopy.level;
    return {
        ...limitationCopy,
        userLicenses: limitationCopy.userLicenses.map(userLicense => {
            const userLicenseCopy = { ...userLicense };
            delete userLicenseCopy.level;
            return userLicenseCopy;
        }),
    };
};
const transformLimitationConsent = (consent) => {
    const limitationsValue = {
        isEnabled: false,
        isDefault: false,
    };
    switch (consent) {
        case CONSENT_TYPE.ACCEPT: {
            limitationsValue.isEnabled = true;
            limitationsValue.isDefault = false;
            break;
        }
        case CONSENT_TYPE.DECLINE: {
            limitationsValue.isEnabled = false;
            limitationsValue.isDefault = false;
            break;
        }
        case CONSENT_TYPE.DEFAULT_ACCEPT: {
            limitationsValue.isEnabled = true;
            limitationsValue.isDefault = true;
            break;
        }
        case CONSENT_TYPE.DEFAULT_DECLINE: {
            limitationsValue.isEnabled = false;
            limitationsValue.isDefault = true;
            break;
        }
    }
    return limitationsValue;
};
const convertSelectedLimitationsToOutput = (consent) => consent
    ? {
        isEnabled: true,
        isDefault: false,
    }
    : {
        isEnabled: false,
        isDefault: false,
    };
export const transformConsent = (limitation) => {
    const limitationCopy = JSON.parse(JSON.stringify(limitation));
    const consentValue = limitationCopy.consent;
    delete limitationCopy.consent;
    const limitationsValue = transformLimitationConsent(consentValue);
    limitationCopy.isEnabled = limitationsValue.isEnabled;
    limitationCopy.isDefault = limitationsValue.isDefault;
    return limitationCopy;
};
const transformConsentField = (limitation) => {
    const limitationCopy = JSON.parse(JSON.stringify(limitation));
    const transformedInitialLayer = transformConsent(limitationCopy);
    return {
        ...transformedInitialLayer,
        userLicenses: transformedInitialLayer.userLicenses.map(userLicense => transformConsent(userLicense)),
    };
};
export const transformLimitation = (limitation) => {
    const limitationWithoutLevel = removeLevelField(limitation);
    return transformConsentField(limitationWithoutLevel);
};
export const transformLimitations = (purposeLimitations) => purposeLimitations.map(purposeLimitation => {
    const limitationWithoutLevel = removeLevelField(purposeLimitation);
    return transformConsentField(limitationWithoutLevel);
});
export const fetchPurposeLimitationsMember = async (accessToken, userExperience) => {
    const result = await API.getPurposeLimitationsMember(accessToken, userExperience);
    if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
    }
    const purposeLimitations = await result.json();
    return camelizeKeys(purposeLimitations);
};
export const fetchPurposeLimitationsGuest = async (userExperience, location) => {
    if (!location) {
        throw new Error('Location is required to get Purpose Limitations');
    }
    const result = await API.getPurposeLimitationsGuest(userExperience, location);
    if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
    }
    const purposeLimitations = await result.json();
    return camelizeKeys(purposeLimitations);
};
const getPurposeLimitationsBodyUpdate = (props, language) => decamelizeKeys({
    ...props,
    language,
    selectionTimestamp: new Date().toISOString(),
    consentSelections: props.consentSelections.map(consentSelection => ({
        ...consentSelection,
        consent: consentSelection.consent === true ? CONSENT_TYPE.ACCEPT : CONSENT_TYPE.DECLINE,
    })),
});
export const updatePurposeLimitationsMember = async ({ contextFrameToUpdate, accessToken, language, userExperience, }) => {
    if (!accessToken ||
        !contextFrameToUpdate.contextFrameId ||
        !contextFrameToUpdate.contextFrameVersionId) {
        throw new Error('Incorrect Context Frames data provided to update Purpose limitations');
    }
    const { contextFrameId, ...restContextFrameToUpdate } = contextFrameToUpdate;
    const contextFrameToUpdateBody = getPurposeLimitationsBodyUpdate(restContextFrameToUpdate, language);
    const result = await API.updatePurposeLimitations({
        accessToken,
        contextFrameToUpdateBody,
        contextFrameId,
        userExperience,
    });
    if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
    }
    const purposeLimitations = await result.json();
    return camelizeKeys(purposeLimitations);
};
export const transformConsentsFromBooleanValue = (consentSelections) => consentSelections.reduce((accumulator, currentValue) => ({
    ...accumulator,
    [currentValue.purposeLimitationCategory]: convertSelectedLimitationsToOutput(currentValue.consent),
}), {});
export const transformGuestPurposeLimitations = (purposeLimitations, consentSelectionAttachedToCategory) => purposeLimitations.map((purposeLimitation) => {
    if (typeof consentSelectionAttachedToCategory[purposeLimitation.category] === 'undefined') {
        return purposeLimitation;
    }
    const purposeLimitationCopy = JSON.parse(JSON.stringify(purposeLimitation));
    const { isEnabled, isDefault } = consentSelectionAttachedToCategory[purposeLimitation.category];
    purposeLimitationCopy.isEnabled = isEnabled;
    purposeLimitationCopy.isDefault = isDefault;
    purposeLimitationCopy.userLicenses.forEach((userLicense) => {
        userLicense.isEnabled = isEnabled;
        userLicense.isDefault = isDefault;
    });
    return purposeLimitationCopy;
});
export const updatePurposeLimitationsGuest = (contextFrameToUpdate) => {
    if (!contextFrameToUpdate.contextFrameId || !contextFrameToUpdate.contextFrameVersionId) {
        throw new Error('Incorrect Context Frames data provided to update Purpose limitations');
    }
    const { consentSelections } = contextFrameToUpdate;
    const { purposeLimitations } = getPurposeLimitationsGuestState(store.getState());
    return transformGuestPurposeLimitations(purposeLimitations, transformConsentsFromBooleanValue(consentSelections));
};
export const getIsExpirationTimeExpired = (expiration) => {
    const currentMilliseconds = Date.now();
    const expirationInMilliseconds = expiration * 1000;
    return expirationInMilliseconds <= currentMilliseconds;
};
export const checkRetrievingLimitationMemberFromStore = () => {
    const { purposeLimitationsToken, purposeLimitations, error } = getPurposeLimitationsMemberState(store.getState());
    if (error ||
        !purposeLimitations ||
        !purposeLimitations.length ||
        !purposeLimitationsToken ||
        !purposeLimitationsToken.tokenBody ||
        !purposeLimitationsToken.expires)
        return false;
    return true;
};
export const checkRetrievingLimitationGuestFromStore = () => {
    const { purposeLimitations, error } = getPurposeLimitationsGuestState(store.getState());
    return !(error || !purposeLimitations || !purposeLimitations.length);
};
export const getFullPurposeLimitationsForStorePayload = (transformedPurposeLimitationsServer, purposeLimitationsState) => {
    const purposeLimitationsServerCategories = transformedPurposeLimitationsServer.map(transformedPurposeLimitation => transformedPurposeLimitation.category);
    const purposeLimitationsStateFiltered = purposeLimitationsState.filter(purposeLimitationState => !purposeLimitationsServerCategories.includes(purposeLimitationState.category));
    return [...purposeLimitationsStateFiltered, ...transformedPurposeLimitationsServer];
};
export const getConsentSelectionsForCookie = (purposeLimitation) => {
    return purposeLimitation.isEnabled === true ? '1' : '0';
};
export const getCookiesPurposeLimitationsEnumKeys = (enumKey) => {
    return COOKIE_PURPOSE_LIMITATION_NAMES[enumKey];
};
export const getServerPurposeLimitationEnumKey = (purposeLimitation) => {
    const purposeLimitationEnumKeys = Object.keys(SERVER_PURPOSE_LIMITATION_NAMES);
    const purposeLimitationsEnumValues = Object.values(SERVER_PURPOSE_LIMITATION_NAMES);
    const indexOfPurposeLimitationEnumValue = purposeLimitationsEnumValues.indexOf(purposeLimitation.category);
    return indexOfPurposeLimitationEnumValue >= 0
        ? purposeLimitationEnumKeys[indexOfPurposeLimitationEnumValue]
        : undefined;
};
export const getCookiePurposeLimitationsValuesToSet = (purposeLimitations) => {
    const cookieEnumKeysWithConsents = purposeLimitations.map(purposeLimitation => {
        const serverPurposeLimitationEnumKey = getServerPurposeLimitationEnumKey(purposeLimitation);
        if (typeof serverPurposeLimitationEnumKey === 'undefined') {
            return;
        }
        const cookieEnumKey = getCookiesPurposeLimitationsEnumKeys(serverPurposeLimitationEnumKey);
        if (typeof cookieEnumKey === 'undefined') {
            return;
        }
        const cookieConsent = getConsentSelectionsForCookie(purposeLimitation);
        return `${cookieEnumKey}=${cookieConsent}`;
    });
    return cookieEnumKeysWithConsents
        .filter(cookieEnumKeyWithConsent => typeof cookieEnumKeyWithConsent !== 'undefined')
        .join('|');
};
