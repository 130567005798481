"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SERVER_PURPOSE_LIMITATION_NAMES = exports.SERVER_CONTEXT_FRAME_NAMES = exports.PURPOSE_LIMITATION_NAMES = exports.PURPOSE_LIMITATIONS_KEY = exports.PRIVACY_CORE_EVENT_NAMESPACE = exports.PRIVACY_CORE_CUSTOM_EVENT_NAMES = exports.NOT_REQUIRED_MODAL_CONTEXT_LEVEL = exports.NOT_REQUIRED_INLINE_CONTEXT_LEVEL = exports.INLINE_COOKIE_EVENT_NAMESPACE = exports.INLINE_COOKIE_CUSTOM_EVENT_NAMES = exports.CPRA_CUSTOM_EVENT_NAMES = exports.COOKIE_PURPOSE_LIMITATION_NAMES = exports.CONTEXT_FRAME_NAMES = exports.CONTEXT_FRAMES_KEY = exports.CONSENT_TYPE_MEMBER = exports.CONSENT_TYPE_GUEST = exports.CONSENT_TYPE = exports.CONSENT_SELECTION_SOURCE = void 0;
var PURPOSE_LIMITATIONS_KEY = 'purposeLimitations';
exports.PURPOSE_LIMITATIONS_KEY = PURPOSE_LIMITATIONS_KEY;
var CONTEXT_FRAMES_KEY = 'contextFrames';
/**
 * @name NOT_REQUIRED_INLINE_CONTEXT_LEVEL
 * @description The context with a specific level should be skipped for the user in inline component type.
 * @const {number}
 */

exports.CONTEXT_FRAMES_KEY = CONTEXT_FRAMES_KEY;
var NOT_REQUIRED_INLINE_CONTEXT_LEVEL = 1;
/**
 * @name NOT_REQUIRED_MODAL_CONTEXT_LEVEL
 * @description The context with a specific level should be skipped for the user in modal component type.
 * @const {number}
 */

exports.NOT_REQUIRED_INLINE_CONTEXT_LEVEL = NOT_REQUIRED_INLINE_CONTEXT_LEVEL;
var NOT_REQUIRED_MODAL_CONTEXT_LEVEL = 2;
/**
 * @name CONTEXT_FRAME_NAMES
 * @description Enum for representing context frame names value.
 * @enum {string}
 * @typedef CONTEXT_FRAME_NAMES
 * @property {string} WEB_GUEST_MODAL - Modal that guests see on first visit
 * @property {string} WEB_MEMBER_MODAL - Modal members see post-login
 * @property {string} WEB_MEMBER_SETTINGS - Privacy settings page for members
 * @property {string} WEB_GUEST_SETTINGS - Privacy settings page for guests
 */

exports.NOT_REQUIRED_MODAL_CONTEXT_LEVEL = NOT_REQUIRED_MODAL_CONTEXT_LEVEL;
var CONTEXT_FRAME_NAMES;
/**
 * @name SERVER_CONTEXT_FRAME_NAMES
 * @description Enum for representing context frame names for not transformed values from server.
 * @enum {string}
 * @typedef CONTEXT_FRAME_NAMES
 * @property {string} WEB_GUEST_MODAL - Modal that guests see on first visit
 * @property {string} WEB_MEMBER_MODAL - Modal members see post-login
 * @property {string} WEB_MEMBER_SETTINGS - Privacy settings page for members
 * @property {string} WEB_GUEST_SETTINGS - Privacy settings page for guests
 */

exports.CONTEXT_FRAME_NAMES = CONTEXT_FRAME_NAMES;

(function (CONTEXT_FRAME_NAMES) {
  CONTEXT_FRAME_NAMES["WEB_GUEST_MODAL"] = "webGuestModal";
  CONTEXT_FRAME_NAMES["WEB_MEMBER_MODAL"] = "webMemberModal";
  CONTEXT_FRAME_NAMES["WEB_MEMBER_SETTINGS"] = "webMemberSettings";
  CONTEXT_FRAME_NAMES["WEB_GUEST_SETTINGS"] = "webGuestSettings";
})(CONTEXT_FRAME_NAMES || (exports.CONTEXT_FRAME_NAMES = CONTEXT_FRAME_NAMES = {}));

var SERVER_CONTEXT_FRAME_NAMES;
/**
 * @name PURPOSE_LIMITATION_NAMES
 * @description Enum for targeting Purpose Limitation names.
 * @enum {string}
 */

exports.SERVER_CONTEXT_FRAME_NAMES = SERVER_CONTEXT_FRAME_NAMES;

(function (SERVER_CONTEXT_FRAME_NAMES) {
  SERVER_CONTEXT_FRAME_NAMES["WEB_GUEST_MODAL"] = "web_guest_modal";
  SERVER_CONTEXT_FRAME_NAMES["WEB_MEMBER_MODAL"] = "web_member_modal";
  SERVER_CONTEXT_FRAME_NAMES["WEB_MEMBER_SETTINGS"] = "web_member_settings";
  SERVER_CONTEXT_FRAME_NAMES["WEB_GUEST_SETTINGS"] = "web_guest_settings";
})(SERVER_CONTEXT_FRAME_NAMES || (exports.SERVER_CONTEXT_FRAME_NAMES = SERVER_CONTEXT_FRAME_NAMES = {}));

var PURPOSE_LIMITATION_NAMES;
/**
 * @name SERVER_PURPOSE_LIMITATION_NAMES
 * @description Enum for targeting Purpose Limitation names for not transformed values from server.
 * @enum {string}
 */

exports.PURPOSE_LIMITATION_NAMES = PURPOSE_LIMITATION_NAMES;

(function (PURPOSE_LIMITATION_NAMES) {
  PURPOSE_LIMITATION_NAMES["PERSONALIZATION_RECOMMENDATION"] = "personalizationRecommendation";
  PURPOSE_LIMITATION_NAMES["TARGETING_BEHAVIORAL_EVENTS"] = "adtargetingBehavioralevents";
  PURPOSE_LIMITATION_NAMES["PERFORMANCE"] = "performance";
  PURPOSE_LIMITATION_NAMES["TARGETING_HASHEDMATCH"] = "adtargetingHashedmatch";
})(PURPOSE_LIMITATION_NAMES || (exports.PURPOSE_LIMITATION_NAMES = PURPOSE_LIMITATION_NAMES = {}));

var SERVER_PURPOSE_LIMITATION_NAMES;
/**
 * @name COOKIE_PURPOSE_LIMITATION_NAMES
 * @description Enum for Purpose Limitation names' cookie values used within ni_c cookie key.
 * @enum {string}
 */

exports.SERVER_PURPOSE_LIMITATION_NAMES = SERVER_PURPOSE_LIMITATION_NAMES;

(function (SERVER_PURPOSE_LIMITATION_NAMES) {
  SERVER_PURPOSE_LIMITATION_NAMES["PERSONALIZATION_RECOMMENDATION"] = "personalization_recommendation";
  SERVER_PURPOSE_LIMITATION_NAMES["TARGETING_BEHAVIORAL_EVENTS"] = "adtargeting_behavioralevents";
  SERVER_PURPOSE_LIMITATION_NAMES["PERFORMANCE"] = "performance";
  SERVER_PURPOSE_LIMITATION_NAMES["TARGETING_HASHEDMATCH"] = "adtargeting_hashedmatch";
})(SERVER_PURPOSE_LIMITATION_NAMES || (exports.SERVER_PURPOSE_LIMITATION_NAMES = SERVER_PURPOSE_LIMITATION_NAMES = {}));

var COOKIE_PURPOSE_LIMITATION_NAMES;
/**
 * @name CONSENT_TYPE
 * @description Enum for representing purposeLimitation consent type.
 * @enum {string}
 */

exports.COOKIE_PURPOSE_LIMITATION_NAMES = COOKIE_PURPOSE_LIMITATION_NAMES;

(function (COOKIE_PURPOSE_LIMITATION_NAMES) {
  COOKIE_PURPOSE_LIMITATION_NAMES["TARGETING_BEHAVIORAL_EVENTS"] = "BEAD";
  COOKIE_PURPOSE_LIMITATION_NAMES["PERFORMANCE"] = "PERF";
  COOKIE_PURPOSE_LIMITATION_NAMES["TARGETING_HASHEDMATCH"] = "1PA";
})(COOKIE_PURPOSE_LIMITATION_NAMES || (exports.COOKIE_PURPOSE_LIMITATION_NAMES = COOKIE_PURPOSE_LIMITATION_NAMES = {}));

var CONSENT_TYPE;
/**
 * @name CONSENT_TYPE_MEMBER
 * @description Enum for representing purposeLimitation consent type for members.
 * @enum {string}
 */

exports.CONSENT_TYPE = CONSENT_TYPE;

(function (CONSENT_TYPE) {
  CONSENT_TYPE["ACCEPT"] = "accept";
  CONSENT_TYPE["DECLINE"] = "decline";
  CONSENT_TYPE["DEFAULT_ACCEPT"] = "default_accept";
  CONSENT_TYPE["DEFAULT_DECLINE"] = "default_decline";
})(CONSENT_TYPE || (exports.CONSENT_TYPE = CONSENT_TYPE = {}));

var CONSENT_TYPE_MEMBER;
/**
 * @name CONSENT_TYPE_GUEST
 * @description Enum for representing purposeLimitation consent type for guests.
 * @enum {string}
 */

exports.CONSENT_TYPE_MEMBER = CONSENT_TYPE_MEMBER;

(function (CONSENT_TYPE_MEMBER) {
  CONSENT_TYPE_MEMBER["ACCEPT"] = "accept";
  CONSENT_TYPE_MEMBER["DECLINE"] = "decline";
  CONSENT_TYPE_MEMBER["DEFAULT_ACCEPT"] = "default_accept";
  CONSENT_TYPE_MEMBER["DEFAULT_DECLINE"] = "default_decline";
})(CONSENT_TYPE_MEMBER || (exports.CONSENT_TYPE_MEMBER = CONSENT_TYPE_MEMBER = {}));

var CONSENT_TYPE_GUEST;
/**
 * @name CONSENT_SELECTION_SOURCE
 * @description Enum for representing the source of consent selection.
 * @enum {string}
 */

exports.CONSENT_TYPE_GUEST = CONSENT_TYPE_GUEST;

(function (CONSENT_TYPE_GUEST) {
  CONSENT_TYPE_GUEST["DEFAULT_ACCEPT"] = "default_accept";
  CONSENT_TYPE_GUEST["DEFAULT_DECLINE"] = "default_decline";
})(CONSENT_TYPE_GUEST || (exports.CONSENT_TYPE_GUEST = CONSENT_TYPE_GUEST = {}));

var CONSENT_SELECTION_SOURCE;
/**
 * @name INLINE_COOKIE_EVENT_NAMESPACE
 * @description Const representing namespace for inline cookie custom events.
 * @const {string}
 */

exports.CONSENT_SELECTION_SOURCE = CONSENT_SELECTION_SOURCE;

(function (CONSENT_SELECTION_SOURCE) {
  CONSENT_SELECTION_SOURCE["ADVERTISING_DATA_SHARING_CPRA_SWITCH"] = "ADVERTISING_DATA_SHARING_CPRA_SWITCH";
  CONSENT_SELECTION_SOURCE["DIRECT"] = "DIRECT";
})(CONSENT_SELECTION_SOURCE || (exports.CONSENT_SELECTION_SOURCE = CONSENT_SELECTION_SOURCE = {}));

var INLINE_COOKIE_EVENT_NAMESPACE = '@nikeInlineCookieCustomEvent/';
/**
 * @name PRIVACY_CORE_EVENT_NAMESPACE
 * @description Const representing namespace for privacy core custom events.
 * @const {string}
 */

exports.INLINE_COOKIE_EVENT_NAMESPACE = INLINE_COOKIE_EVENT_NAMESPACE;
var PRIVACY_CORE_EVENT_NAMESPACE = '@nikePrivacyCoreCustomEvent/';
/**
 * @name CPRA_EVENT_NAMESPACE
 * @description Const representing namespace for cpra custom events.
 * @const {string}
 */

exports.PRIVACY_CORE_EVENT_NAMESPACE = PRIVACY_CORE_EVENT_NAMESPACE;
var CPRA_EVENT_NAMESPACE = '@nikeCpraCustomEvent';
/**
 * @name INLINE_COOKIE_CUSTOM_EVENT_NAMES
 * @description Object containing inline cookie custom event names.
 * @const {object}
 */

var INLINE_COOKIE_CUSTOM_EVENT_NAMES;
/**
 * @name PRIVACY_CORE_CUSTOM_EVENT_NAMES
 * @description Object containing privacy core custom event names.
 * @const {object}
 */

exports.INLINE_COOKIE_CUSTOM_EVENT_NAMES = INLINE_COOKIE_CUSTOM_EVENT_NAMES;

(function (INLINE_COOKIE_CUSTOM_EVENT_NAMES) {
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["MFE_LOADED"] = "mfeLoaded";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["INITIALIZE_ERROR"] = "initializeError";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["PERMISSIONS_UPDATE_ERROR"] = "permissionsUpdateError";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["LEARN_MORE_IS_SHOWN"] = "learnMoreIsShown";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["LEARN_MORE_IS_HIDDEN"] = "learnMoreIsHidden";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["SHOW_LOADER"] = "showLoader";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["HIDE_LOADER"] = "hideLoader";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["SHOW_INLINE_ERROR"] = "showInlineError";
  INLINE_COOKIE_CUSTOM_EVENT_NAMES["SHOW_UPDATE_ERROR"] = "showUpdateError";
})(INLINE_COOKIE_CUSTOM_EVENT_NAMES || (exports.INLINE_COOKIE_CUSTOM_EVENT_NAMES = INLINE_COOKIE_CUSTOM_EVENT_NAMES = {}));

var PRIVACY_CORE_CUSTOM_EVENT_NAMES;
/**
 * @name CPRA_CUSTOM_EVENT_NAMES
 * @description Object containing cpra custom event names.
 * @const {object}
 */

exports.PRIVACY_CORE_CUSTOM_EVENT_NAMES = PRIVACY_CORE_CUSTOM_EVENT_NAMES;

(function (PRIVACY_CORE_CUSTOM_EVENT_NAMES) {
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["INITIALIZED"] = "initialized";
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["INITIALIZE_ERROR"] = "initializeError";
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["CONTEXT_FRAMES_GET_ERROR"] = "contextFramesGetError";
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["PERMISSIONS_UPDATING"] = "permissionsUpdating";
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["PERMISSIONS_UPDATED"] = "permissionsUpdated";
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["PERMISSIONS_GET_ERROR"] = "permissionsGetError";
  PRIVACY_CORE_CUSTOM_EVENT_NAMES["PERMISSIONS_UPDATE_ERROR"] = "permissionsUpdateError";
})(PRIVACY_CORE_CUSTOM_EVENT_NAMES || (exports.PRIVACY_CORE_CUSTOM_EVENT_NAMES = PRIVACY_CORE_CUSTOM_EVENT_NAMES = {}));

var CPRA_CUSTOM_EVENT_NAMES = {
  PERMISSIONS_UPDATE_ERROR: "".concat(CPRA_EVENT_NAMESPACE, "/permissionsUpdateError")
};
exports.CPRA_CUSTOM_EVENT_NAMES = CPRA_CUSTOM_EVENT_NAMES;