"use strict";

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _fetchCloudFragment = require("./fetchCloudFragment");

Object.keys(_fetchCloudFragment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _fetchCloudFragment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _fetchCloudFragment[key];
    }
  });
});

var _fetchLocation = require("./fetchLocation");

Object.keys(_fetchLocation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _fetchLocation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _fetchLocation[key];
    }
  });
});

var _fetchLanguage = require("./fetchLanguage");

Object.keys(_fetchLanguage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _fetchLanguage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _fetchLanguage[key];
    }
  });
});

var _getIsLoggedIn = require("./getIsLoggedIn");

Object.keys(_getIsLoggedIn).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getIsLoggedIn[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getIsLoggedIn[key];
    }
  });
});

var _customEvents = require("./customEvents");

Object.keys(_customEvents).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _customEvents[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _customEvents[key];
    }
  });
});