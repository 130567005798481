"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCustomEvent = createCustomEvent;
exports.dispatchCustomEvent = dispatchCustomEvent;
exports.dispatchEvent = dispatchEvent;
exports.getPrivacyCoreEventName = exports.getInlineEventName = void 0;

require("core-js/modules/es.array.concat.js");

var _constants = require("../constants");

/**
 * @name createCustomEvent
 * @description A wrapper for creating custom events
 * @param {string} eventName - Event name string to create a CustomEvent with
 * @param {CustomEventOptions} customEventOptions - Options for creating
 * @returns {CustomEvent} A new CustomEvent @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEventhttps://developer.mozilla.org/en-US/docs/Web/API/CustomEvent}
 */
function createCustomEvent(eventName, customEventOptions) {
  // NOTE - CustomEvent not supported in IE
  return new CustomEvent(eventName, customEventOptions);
}
/**
 * @name dispatchCustomEvent
 * @description A wrapper for dispatching custom events for now
 * @param {CustomEvent} customEvent - A native CustomEvent @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEventhttps://developer.mozilla.org/en-US/docs/Web/API/CustomEvent}
 */


function dispatchCustomEvent(customEvent) {
  window.dispatchEvent(customEvent);
}
/**
 * @name dispatchEvent
 * @description A wrapper for dispatching custom events for now
 * @param {string} eventName - Event name string to create a CustomEvent with
 * @param {CustomEventOptions} customEventOptions - Options for creating
 */


function dispatchEvent(eventName, customEventOptions) {
  dispatchCustomEvent(createCustomEvent(eventName, customEventOptions));
}

/**
 * @name getPrivacyCoreEventName
 * @description Returns a custom event names based on passed name and uniques event prefix
 * @param {PrivacyCoreCustomEventName} name - Name of the custom event
 * @param {string} [uniqueEventsPrefix] - A unique extra prefix for custom events
 * @returns {string}
 */
var getPrivacyCoreEventName = function getPrivacyCoreEventName(name) {
  var uniqueEventsPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return "".concat(_constants.PRIVACY_CORE_EVENT_NAMESPACE).concat(uniqueEventsPrefix).concat(name);
};

exports.getPrivacyCoreEventName = getPrivacyCoreEventName;

/**
 * @name getPrivacyCoreEventName
 * @description Returns a custom event names based on passed name and uniques event prefix
 * @param {InlineCustomEventName} name - Name of the custom event
 * @param {string} [uniqueEventsPrefix] - A unique extra prefix for custom events
 * @returns {string}
 */
var getInlineEventName = function getInlineEventName(name) {
  var uniqueEventsPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return "".concat(_constants.INLINE_COOKIE_EVENT_NAMESPACE).concat(uniqueEventsPrefix).concat(name);
};

exports.getInlineEventName = getInlineEventName;