import storage from 'redux-persist/lib/storage';
import { CONTEXT_FRAMES_KEY, PURPOSE_LIMITATIONS_KEY } from '@nike/privacy-shared-logic';
export const rootPersistConfig = {
    key: 'nike-privacy',
    storage,
    blacklist: [PURPOSE_LIMITATIONS_KEY, CONTEXT_FRAMES_KEY],
};
export const purposeLimitationsConfig = {
    key: PURPOSE_LIMITATIONS_KEY,
    storage,
    blacklist: ['error'],
};
export const contextFramesConfig = {
    key: CONTEXT_FRAMES_KEY,
    storage,
    blacklist: ['error'],
};
export var GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES;
(function (GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES) {
    GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES["GET_CONTEXT_FRAMES_MEMBER_PENDING"] = "@privacy-core/GET_CONTEXT_FRAMES_MEMBER_PENDING";
    GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES["GET_CONTEXT_FRAMES_MEMBER_SUCCESS"] = "@privacy-core/GET_CONTEXT_FRAMES_MEMBER_SUCCESS";
    GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES["GET_CONTEXT_FRAMES_MEMBER_FAIL"] = "@privacy-core/GET_CONTEXT_FRAMES_MEMBER_FAIL";
})(GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES || (GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES = {}));
export var GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES;
(function (GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES) {
    GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES["GET_CONTEXT_FRAMES_GUEST_PENDING"] = "@privacy-core/GET_CONTEXT_FRAMES_GUEST_PENDING";
    GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES["GET_CONTEXT_FRAMES_GUEST_SUCCESS"] = "@privacy-core/GET_CONTEXT_FRAMES_GUEST_SUCCESS";
    GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES["GET_CONTEXT_FRAMES_GUEST_FAIL"] = "@privacy-core/GET_CONTEXT_FRAMES_GUEST_FAIL";
})(GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES || (GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES = {}));
export const CONTEXT_FRAMES_ACTION_TYPES = {
    ...GET_CONTEXT_FRAMES_MEMBER_ACTION_TYPES,
    ...GET_CONTEXT_FRAMES_GUEST_ACTION_TYPES,
    RESET_CONTEXT_FRAMES_MEMBER: '@privacy-core/RESET_CONTEXT_FRAMES_MEMBER',
};
export var GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES;
(function (GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES) {
    GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES["GET_PURPOSE_LIMITATIONS_MEMBER_PENDING"] = "@privacy-core/GET_PURPOSE_LIMITATIONS_MEMBER_PENDING";
    GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES["GET_PURPOSE_LIMITATIONS_MEMBER_SUCCESS"] = "@privacy-core/GET_PURPOSE_LIMITATIONS_MEMBER_SUCCESS";
    GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES["GET_PURPOSE_LIMITATIONS_MEMBER_FAIL"] = "@privacy-core/GET_PURPOSE_LIMITATIONS_MEMBER_FAIL";
})(GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES || (GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES = {}));
export var GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES;
(function (GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES) {
    GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES["GET_PURPOSE_LIMITATIONS_GUEST_PENDING"] = "@privacy-core/GET_PURPOSE_LIMITATIONS_GUEST_PENDING";
    GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES["GET_PURPOSE_LIMITATIONS_GUEST_SUCCESS"] = "@privacy-core/GET_PURPOSE_LIMITATIONS_GUEST_SUCCESS";
    GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES["GET_PURPOSE_LIMITATIONS_GUEST_FAIL"] = "@privacy-core/GET_PURPOSE_LIMITATIONS_GUEST";
})(GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES || (GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES = {}));
export var UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES;
(function (UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES) {
    UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES["UPDATE_PURPOSE_LIMITATIONS_MEMBER_PENDING"] = "@privacy-core/UPDATE_PURPOSE_LIMITATIONS_MEMBER_PENDING";
    UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES["UPDATE_PURPOSE_LIMITATIONS_MEMBER_SUCCESS"] = "@privacy-core/UPDATE_PURPOSE_LIMITATIONS_MEMBER_SUCCESS";
    UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES["UPDATE_PURPOSE_LIMITATIONS_MEMBER_FAIL"] = "@privacy-core/UPDATE_PURPOSE_LIMITATIONS_MEMBER_FAIL";
})(UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES || (UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES = {}));
export var UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES;
(function (UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES) {
    UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES["UPDATE_PURPOSE_LIMITATIONS_GUEST_PENDING"] = "@privacy-core/UPDATE_PURPOSE_LIMITATIONS_GUEST_PENDING";
    UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES["UPDATE_PURPOSE_LIMITATIONS_GUEST_SUCCESS"] = "@privacy-core/UPDATE_PURPOSE_LIMITATIONS_GUEST_SUCCESS";
    UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES["UPDATE_PURPOSE_LIMITATIONS_GUEST_FAIL"] = "@privacy-core/UPDATE_PURPOSE_LIMITATIONS_GUEST_FAIL";
})(UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES || (UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES = {}));
export const PURPOSE_LIMITATIONS_ACTION_TYPES = {
    ...GET_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES,
    ...GET_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES,
    ...UPDATE_PURPOSE_LIMITATIONS_MEMBER_ACTION_TYPES,
    ...UPDATE_PURPOSE_LIMITATIONS_GUEST_ACTION_TYPES,
    RESET_PURPOSE_LIMITATIONS_MEMBER: '@privacy-core/RESET_PURPOSE_LIMITATIONS_MEMBER',
};
export const COOKIE_PURPOSE_LIMITATIONS_EXPIRATION_PERIOD = 395;
