import { createSelector } from 'reselect';
const getContextFramesMemberState = createSelector((state) => state.contextFrames, contextFrames => contextFrames.member || {
    contextFrames: [],
    language: '',
    eTag: '',
    lastModified: '',
    loading: false,
    error: null,
});
export default getContextFramesMemberState;
