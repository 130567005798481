import { PRIVACY_CORE_CUSTOM_EVENT_NAMES, dispatchEvent, getPrivacyCoreEventName, } from '@nike/privacy-shared-logic';
import Cookies from 'js-cookie';
import { checkRetrievingLimitationMemberFromStore, checkRetrievingLimitationGuestFromStore, getPurposeLimitationByName, } from './purposeLimitations';
import { getContextFrameByName } from './contextFrames';
import { privacyCookieModalSeenKey, allowedCountriesWithNikePrivacy } from '../constants';
import { getContextFramesGuestAction, getContextFramesMemberAction, resetContextFramesMemberAction, } from '../store/contextFrames/actions';
import { getPurposeLimitationsMemberAction, getPurposeLimitationsGuestAction, updatePurposeLimitationsMemberAction, updatePurposeLimitationsGuestAction, resetPurposeLimitationsMemberAction, } from '../store/purposeLimitations/actions';
import getContextFramesMemberState from '../store/selectors/getContextFramesMemberState';
import getPurposeLimitationsMemberState from '../store/selectors/getPurposeLimitationsMemberState';
import getPurposeLimitationsGuestState from '../store/selectors/getPurposeLimitationsGuestState';
import getContextFramesGuestState from '../store/selectors/getContextFramesGuestState';
export class PrivacyServices {
    constructor(store) {
        this.getLanguage = language => {
            switch (language) {
                case 'no-NO': {
                    return 'nb-NO';
                }
                case 'zh-Hant-TW': {
                    return 'zh-TW';
                }
                case 'es-LA': {
                    return 'es-MX';
                }
                default: {
                    return language;
                }
            }
        };
        this.getGuestId = async (webShellIdentity) => {
            const visitData = await webShellIdentity.getVisitData();
            if (!visitData)
                return;
            return visitData.visitor || visitData.visitorId;
        };
        this.getMemberId = async (webShellIdentity) => { var _a; return (_a = (await webShellIdentity.getUserProfile())) === null || _a === void 0 ? void 0 : _a.upmId; };
        this.getUserId = async (accessToken, webShellIdentity) => {
            const userId = accessToken
                ? await this.getMemberId(webShellIdentity)
                : await this.getGuestId(webShellIdentity);
            if (typeof userId === 'undefined') {
                throw new Error('Get user id error');
            }
            return userId;
        };
        this.checkIsEnabled = () => {
            var _a, _b, _c, _d;
            const privacyCoreEnabledCookie = Cookies.get('privacyCoreEnabled');
            const privacyCoreEnabledCountriesCookie = Cookies.get('privacyCoreEnabledCountries');
            const privacyCoreDisabledCountriesCookie = Cookies.get('privacyCoreDisabledCountries');
            const country = ((_d = (_c = (_b = (_a = window.webShellClient) === null || _a === void 0 ? void 0 : _a.locale) === null || _b === void 0 ? void 0 : _b.get) === null || _c === void 0 ? void 0 : _c.call(_b).country) === null || _d === void 0 ? void 0 : _d.toUpperCase()) || '';
            const parsedEnabledCountries = privacyCoreEnabledCountriesCookie && privacyCoreEnabledCountriesCookie.split(',');
            const parsedDisabledCountries = privacyCoreDisabledCountriesCookie && privacyCoreDisabledCountriesCookie.split(',');
            if (!country)
                return false;
            if (privacyCoreEnabledCookie)
                return privacyCoreEnabledCookie === 'true';
            if (parsedEnabledCountries && parsedEnabledCountries.includes(country))
                return true;
            if (parsedDisabledCountries && parsedDisabledCountries.includes(country))
                return false;
            return allowedCountriesWithNikePrivacy.includes(country);
        };
        this.checkIsCPRAEnabled = () => Cookies.get('cpraEnabled') === 'true';
        this.initializeMember = async ({ accessToken, userId, language, userExperience, }) => {
            await this.store.dispatch(getPurposeLimitationsMemberAction({ accessToken, userId, userExperience }));
            await this.store.dispatch(getContextFramesMemberAction({ language, userExperience }));
            const state = this.store.getState();
            const { error: purposeLimitationsError } = getPurposeLimitationsMemberState(state);
            const { error: contextFramesError } = getContextFramesMemberState(state);
            if (purposeLimitationsError || contextFramesError)
                throw new Error('Initialize member error');
        };
        this.initializeGuest = async ({ userId, language, userExperience, location, }) => {
            await this.store.dispatch(getPurposeLimitationsGuestAction({ userId, userExperience, location }));
            await this.store.dispatch(getContextFramesGuestAction({ language, userExperience }));
            const state = this.store.getState();
            const guestPurposeLimitationsError = getPurposeLimitationsGuestState(state).error;
            const guestContextFramesError = getContextFramesGuestState(state).error;
            const memberPurposeLimitations = getPurposeLimitationsMemberState(state).purposeLimitations;
            const guestPurposeLimitations = getContextFramesMemberState(state).contextFrames;
            if (memberPurposeLimitations.length)
                this.store.dispatch(resetContextFramesMemberAction());
            if (guestPurposeLimitations.length)
                this.store.dispatch(resetPurposeLimitationsMemberAction());
            window.localStorage.removeItem(privacyCookieModalSeenKey);
            if (guestPurposeLimitationsError || guestContextFramesError)
                throw new Error('Initialize guest error');
        };
        this.initialize = async ({ accessToken, userId, language, userExperience, location, }) => {
            return accessToken
                ? await this.initializeMember({ accessToken, userId, language, userExperience })
                : await this.initializeGuest({ userId, language, userExperience, location });
        };
        this.getPermissionMember = name => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationMemberFromStore();
            if (!canRetrieveLimitationFromStore)
                throw new Error('Can not retrieve permissions');
            const { purposeLimitations } = getPurposeLimitationsMemberState(this.store.getState());
            return getPurposeLimitationByName(purposeLimitations, name);
        };
        this.getPermissionGuest = name => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationGuestFromStore();
            if (!canRetrieveLimitationFromStore)
                throw new Error('Can not retrieve permissions');
            const { purposeLimitations } = getPurposeLimitationsGuestState(this.store.getState());
            return getPurposeLimitationByName(purposeLimitations, name);
        };
        this.getPermissionsAllMember = () => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationMemberFromStore();
            if (!canRetrieveLimitationFromStore)
                throw new Error('Can not retrieve permissions');
            const { purposeLimitations } = getPurposeLimitationsMemberState(this.store.getState());
            return purposeLimitations;
        };
        this.getPermissionsAllGuest = () => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationGuestFromStore();
            if (!canRetrieveLimitationFromStore)
                throw new Error('Can not retrieve permissions');
            const { purposeLimitations } = getPurposeLimitationsGuestState(this.store.getState());
            return purposeLimitations;
        };
        this.getStoredGuestPermission = name => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationGuestFromStore();
            if (!canRetrieveLimitationFromStore)
                return;
            const { purposeLimitations } = getPurposeLimitationsGuestState(this.store.getState());
            return getPurposeLimitationByName(purposeLimitations, name);
        };
        this.getPermission = ({ name, accessToken }) => {
            return accessToken ? this.getPermissionMember(name) : this.getPermissionGuest(name);
        };
        this.getPermissionsAll = accessToken => {
            return accessToken ? this.getPermissionsAllMember() : this.getPermissionsAllGuest();
        };
        this.getInteractionGuest = () => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationGuestFromStore();
            const { contextFrames, error: contextFramesError } = getContextFramesGuestState(this.store.getState());
            if (!canRetrieveLimitationFromStore || contextFramesError)
                throw new Error('Get interaction error');
            return contextFrames;
        };
        this.getInteractionMember = () => {
            const canRetrieveLimitationFromStore = checkRetrievingLimitationMemberFromStore();
            const { contextFrames, error: contextFramesError } = getContextFramesMemberState(this.store.getState());
            if (!canRetrieveLimitationFromStore || contextFramesError)
                throw new Error('Get interaction error');
            return contextFrames;
        };
        this.getInteraction = ({ name, accessToken }) => {
            const contextFrames = accessToken ? this.getInteractionMember() : this.getInteractionGuest();
            if (contextFrames)
                return getContextFrameByName(contextFrames, name);
            return;
        };
        this.setInteractionGuest = async (contextFrameToUpdate, uniqueEventsPrefix) => {
            dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.PERMISSIONS_UPDATING, uniqueEventsPrefix));
            await this.store.dispatch(updatePurposeLimitationsGuestAction(contextFrameToUpdate));
            const { error: purposeLimitationsError } = getPurposeLimitationsGuestState(this.store.getState());
            if (purposeLimitationsError)
                throw new Error('Set interaction error');
        };
        this.setInteractionMember = async ({ accessToken, language, userExperience, contextFrameToUpdate, uniqueEventsPrefix, }) => {
            dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.PERMISSIONS_UPDATING, uniqueEventsPrefix));
            await this.store.dispatch(updatePurposeLimitationsMemberAction({
                accessToken,
                language,
                userExperience,
                contextFrameToUpdate,
            }));
            const { error: purposeLimitationsError } = getPurposeLimitationsMemberState(this.store.getState());
            if (purposeLimitationsError)
                throw new Error('Set interaction error');
            window.localStorage.setItem(privacyCookieModalSeenKey, 'true');
        };
        this.setInteraction = async ({ accessToken, language, userExperience, contextFrameToUpdate, uniqueEventsPrefix, }) => {
            return accessToken
                ? await this.setInteractionMember({
                    accessToken,
                    language,
                    userExperience,
                    contextFrameToUpdate,
                    uniqueEventsPrefix,
                })
                : await this.setInteractionGuest(contextFrameToUpdate, uniqueEventsPrefix);
        };
        this.store = store;
    }
}
