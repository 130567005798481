"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchCloudFragment = void 0;

/**
 * @name fetchCloudFragment
 * @description returns cloud fragment from webshell client
 * @returns {string}
 */
var fetchCloudFragment = function fetchCloudFragment() {
  var _window$webShellClien, _window$webShellClien2, _window$webShellClien3;

  var urlLocale = (_window$webShellClien = window.webShellClient) === null || _window$webShellClien === void 0 ? void 0 : (_window$webShellClien2 = _window$webShellClien.locale) === null || _window$webShellClien2 === void 0 ? void 0 : (_window$webShellClien3 = _window$webShellClien2.get) === null || _window$webShellClien3 === void 0 ? void 0 : _window$webShellClien3.call(_window$webShellClien2).cloudUrlFragment;
  return urlLocale ? "/".concat(urlLocale) : "";
};

exports.fetchCloudFragment = fetchCloudFragment;