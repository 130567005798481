import { PURPOSE_LIMITATIONS_ACTION_TYPES } from '../constants';
export const initialState = {
    guest: {
        userId: null,
        purposeLimitations: [],
        purposeLimitationsLevels: {},
        loading: false,
        error: null,
        lastUpdatedTimestamp: null,
    },
    member: {
        userId: null,
        purposeLimitations: [],
        purposeLimitationsToken: {
            tokenBody: '',
            expires: 0,
        },
        purposeLimitationsLevels: {},
        loading: false,
        error: null,
        lastUpdatedTimestamp: null,
    },
};
const purposeLimitationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_MEMBER_PENDING:
            return {
                ...state,
                member: {
                    ...state.member,
                    loading: true,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_MEMBER_SUCCESS:
            return {
                ...state,
                member: {
                    ...state.member,
                    loading: false,
                    error: null,
                    ...action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_MEMBER_FAIL:
            return {
                ...initialState,
                member: {
                    ...initialState.member,
                    error: action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_GUEST_PENDING:
            return {
                ...state,
                guest: {
                    ...state.guest,
                    loading: true,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_GUEST_SUCCESS:
            return {
                ...state,
                guest: {
                    ...state.guest,
                    loading: false,
                    error: null,
                    ...action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_GUEST_FAIL:
            return {
                ...initialState,
                guest: {
                    ...initialState.guest,
                    error: action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_MEMBER_PENDING:
            return {
                ...state,
                member: {
                    ...state.member,
                    loading: true,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_MEMBER_SUCCESS:
            return {
                ...state,
                member: {
                    ...state.member,
                    loading: false,
                    error: null,
                    ...action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_MEMBER_FAIL:
            return {
                ...initialState,
                guest: {
                    ...state.guest,
                },
                member: {
                    ...initialState.member,
                    error: action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_GUEST_PENDING:
            return {
                ...state,
                guest: {
                    ...state.guest,
                    loading: true,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_GUEST_SUCCESS:
            return {
                ...state,
                guest: {
                    ...state.guest,
                    loading: false,
                    error: null,
                    ...action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_GUEST_FAIL:
            return {
                ...initialState,
                guest: {
                    ...initialState.guest,
                    error: action.payload,
                },
            };
        case PURPOSE_LIMITATIONS_ACTION_TYPES.RESET_PURPOSE_LIMITATIONS_MEMBER:
            return {
                ...state,
                member: {
                    ...initialState.member,
                },
            };
        default:
            return state;
    }
};
export default purposeLimitationsReducer;
