import { createSelector } from 'reselect';
const getContextFramesGuestState = createSelector((state) => state.contextFrames, contextFrames => contextFrames.guest || {
    contextFrames: [],
    language: '',
    eTag: '',
    lastModified: '',
    loading: false,
    error: null,
});
export default getContextFramesGuestState;
