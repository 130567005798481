import { createSelector } from 'reselect';
const getPurposeLimitationsMemberState = createSelector((state) => state.purposeLimitations, purposeLimitations => purposeLimitations.member || {
    userId: null,
    purposeLimitations: [],
    purposeLimitationsToken: {
        tokenBody: '',
        expires: 0,
    },
    purposeLimitationsLevels: {},
    loading: false,
    error: null,
});
export default getPurposeLimitationsMemberState;
