"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchLanguage = void 0;

/**
 * @name fetchLanguage
 * @description returns language from webshell client
 * @returns {string}
 */
var fetchLanguage = function fetchLanguage() {
  var _window$webShellClien, _window$webShellClien2, _window$webShellClien3;

  return ((_window$webShellClien = window.webShellClient) === null || _window$webShellClien === void 0 ? void 0 : (_window$webShellClien2 = _window$webShellClien.locale) === null || _window$webShellClien2 === void 0 ? void 0 : (_window$webShellClien3 = _window$webShellClien2.get) === null || _window$webShellClien3 === void 0 ? void 0 : _window$webShellClien3.call(_window$webShellClien2).hreflang) || '';
};

exports.fetchLanguage = fetchLanguage;