var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _NikePrivacy_accessToken, _NikePrivacy_location, _NikePrivacy_language, _NikePrivacy_userId, _NikePrivacy_PrivacyServicesInstance, _NikePrivacy_isEnabledState, _NikePrivacy_isCPRAEnabledState;
import { CONTEXT_FRAME_NAMES, dispatchEvent, getPrivacyCoreEventName, PRIVACY_CORE_CUSTOM_EVENT_NAMES, PURPOSE_LIMITATION_NAMES, SERVER_CONTEXT_FRAME_NAMES, SERVER_PURPOSE_LIMITATION_NAMES, } from '@nike/privacy-shared-logic';
import { PrivacyServices } from './services/privacyServices';
export class NikePrivacy {
    constructor(store) {
        _NikePrivacy_accessToken.set(this, void 0);
        _NikePrivacy_location.set(this, void 0);
        _NikePrivacy_language.set(this, void 0);
        _NikePrivacy_userId.set(this, void 0);
        _NikePrivacy_PrivacyServicesInstance.set(this, void 0);
        _NikePrivacy_isEnabledState.set(this, undefined);
        _NikePrivacy_isCPRAEnabledState.set(this, undefined);
        this.initialize = async ({ webShellIdentity, country, language }) => {
            try {
                if (typeof __classPrivateFieldGet(this, _NikePrivacy_isEnabledState, "f") === 'undefined') {
                    this.isEnabled;
                }
                if (typeof __classPrivateFieldGet(this, _NikePrivacy_isCPRAEnabledState, "f") === 'undefined') {
                    this.isCPRAEnabled;
                }
                if (!__classPrivateFieldGet(this, _NikePrivacy_isEnabledState, "f")) {
                    throw new Error('NikePrivacy is not allowed to use');
                }
                if (typeof NikePrivacy._userExperience === 'undefined') {
                    throw new Error('user experience is not defined in .env file');
                }
                if (!webShellIdentity) {
                    throw new Error('web shell identity does not exist');
                }
                if (NikePrivacy._initializedInstance) {
                    return await NikePrivacy._initializedInstance;
                }
                if (webShellIdentity.initialize) {
                    await webShellIdentity.initialize();
                }
                __classPrivateFieldSet(this, _NikePrivacy_language, __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").getLanguage(language), "f");
                __classPrivateFieldSet(this, _NikePrivacy_location, country, "f");
                __classPrivateFieldSet(this, _NikePrivacy_accessToken, await webShellIdentity.getAccessToken(), "f");
                __classPrivateFieldSet(this, _NikePrivacy_userId, await __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").getUserId(__classPrivateFieldGet(this, _NikePrivacy_accessToken, "f"), webShellIdentity), "f");
                const initializedResponse = __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").initialize({
                    accessToken: __classPrivateFieldGet(this, _NikePrivacy_accessToken, "f"),
                    userId: __classPrivateFieldGet(this, _NikePrivacy_userId, "f"),
                    language: __classPrivateFieldGet(this, _NikePrivacy_language, "f"),
                    userExperience: NikePrivacy._userExperience,
                    location: __classPrivateFieldGet(this, _NikePrivacy_location, "f"),
                });
                NikePrivacy._initializedInstance = initializedResponse;
                return await initializedResponse;
            }
            catch (error) {
                dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.INITIALIZE_ERROR));
                return Promise.resolve(undefined);
            }
            finally {
                dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.INITIALIZED));
            }
        };
        this.permissions = {
            get: (name, uniqueEventsPrefix) => {
                try {
                    if (typeof NikePrivacy._initializedInstance === 'undefined')
                        throw new Error('NikePrivacy is not initialized');
                    if (typeof name === 'undefined') {
                        const permissionsAll = __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").getPermissionsAll(__classPrivateFieldGet(this, _NikePrivacy_accessToken, "f"));
                        if (!permissionsAll)
                            throw new Error('Can not get all permissions');
                        return permissionsAll;
                    }
                    const permissions = Object.keys(PURPOSE_LIMITATION_NAMES).reduce((previousValue, currentValue) => {
                        previousValue[PURPOSE_LIMITATION_NAMES[currentValue]] = () => __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").getPermission({
                            name: SERVER_PURPOSE_LIMITATION_NAMES[currentValue],
                            accessToken: __classPrivateFieldGet(this, _NikePrivacy_accessToken, "f"),
                        });
                        return previousValue;
                    }, {});
                    if (!permissions[name])
                        throw new Error('Can not get permission');
                    return permissions[name]();
                }
                catch (error) {
                    dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.PERMISSIONS_GET_ERROR, uniqueEventsPrefix !== null && uniqueEventsPrefix !== void 0 ? uniqueEventsPrefix : ''));
                    return;
                }
            },
            _getStoredGuestPermission: (name) => {
                try {
                    if (typeof NikePrivacy._initializedInstance === 'undefined')
                        throw new Error('NikePrivacy is not initialized');
                    const permissions = Object.keys(PURPOSE_LIMITATION_NAMES).reduce((previousValue, currentValue) => {
                        previousValue[PURPOSE_LIMITATION_NAMES[currentValue]] = () => __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").getStoredGuestPermission(SERVER_PURPOSE_LIMITATION_NAMES[currentValue]);
                        return previousValue;
                    }, {});
                    if (!permissions[name])
                        throw new Error('Can not get permission');
                    return permissions[name]();
                }
                catch (error) {
                    return;
                }
            },
        };
        this.interactions = {
            get: (name, uniqueEventsPrefix = '') => {
                try {
                    if (typeof NikePrivacy._initializedInstance === 'undefined')
                        throw new Error('NikePrivacy is not initialized');
                    const interactions = Object.keys(CONTEXT_FRAME_NAMES).reduce((previousValue, currentValue) => {
                        previousValue[CONTEXT_FRAME_NAMES[currentValue]] = () => __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").getInteraction({
                            name: SERVER_CONTEXT_FRAME_NAMES[currentValue],
                            accessToken: __classPrivateFieldGet(this, _NikePrivacy_accessToken, "f"),
                        });
                        return previousValue;
                    }, {});
                    if (!interactions[name])
                        throw new Error('Can not get interaction');
                    return interactions[name]();
                }
                catch (error) {
                    dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.CONTEXT_FRAMES_GET_ERROR, uniqueEventsPrefix));
                    return;
                }
            },
            set: async (contextFrameToUpdate, uniqueEventsPrefix = '') => {
                try {
                    if (typeof NikePrivacy._initializedInstance === 'undefined')
                        throw new Error('NikePrivacy is not initialized');
                    return await __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").setInteraction({
                        accessToken: __classPrivateFieldGet(this, _NikePrivacy_accessToken, "f"),
                        language: __classPrivateFieldGet(this, _NikePrivacy_language, "f"),
                        contextFrameToUpdate: contextFrameToUpdate,
                        userExperience: NikePrivacy._userExperience,
                        uniqueEventsPrefix,
                    });
                }
                catch (error) {
                    dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.PERMISSIONS_UPDATE_ERROR, uniqueEventsPrefix));
                    return Promise.resolve(undefined);
                }
                finally {
                    dispatchEvent(getPrivacyCoreEventName(PRIVACY_CORE_CUSTOM_EVENT_NAMES.PERMISSIONS_UPDATED, uniqueEventsPrefix));
                }
            },
        };
        __classPrivateFieldSet(this, _NikePrivacy_PrivacyServicesInstance, new PrivacyServices(store), "f");
    }
    get isEnabled() {
        try {
            __classPrivateFieldSet(this, _NikePrivacy_isEnabledState, __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").checkIsEnabled(), "f");
            return __classPrivateFieldGet(this, _NikePrivacy_isEnabledState, "f");
        }
        catch (error) {
            return false;
        }
    }
    get isCPRAEnabled() {
        try {
            __classPrivateFieldSet(this, _NikePrivacy_isCPRAEnabledState, __classPrivateFieldGet(this, _NikePrivacy_PrivacyServicesInstance, "f").checkIsCPRAEnabled(), "f");
            return __classPrivateFieldGet(this, _NikePrivacy_isCPRAEnabledState, "f");
        }
        catch (error) {
            return false;
        }
    }
}
_NikePrivacy_accessToken = new WeakMap(), _NikePrivacy_location = new WeakMap(), _NikePrivacy_language = new WeakMap(), _NikePrivacy_userId = new WeakMap(), _NikePrivacy_PrivacyServicesInstance = new WeakMap(), _NikePrivacy_isEnabledState = new WeakMap(), _NikePrivacy_isCPRAEnabledState = new WeakMap();
NikePrivacy._userExperience = process.env.USER_EXPERIENCE;
