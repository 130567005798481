import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { CONTEXT_FRAMES_KEY, PURPOSE_LIMITATIONS_KEY } from '@nike/privacy-shared-logic';
import purposeLimitationsReducer from './purposeLimitations/reducer';
import contextFramesReducer from './contextFrames/reducer';
import { contextFramesConfig, purposeLimitationsConfig, rootPersistConfig } from './constants';
const rootReducer = combineReducers({
    [PURPOSE_LIMITATIONS_KEY]: persistReducer(purposeLimitationsConfig, purposeLimitationsReducer),
    [CONTEXT_FRAMES_KEY]: persistReducer(contextFramesConfig, contextFramesReducer),
});
export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistor = persistStore(store);
export { store, persistor };
