import Cookies from 'js-cookie';
import { checkRetrievingLimitationGuestFromStore, checkRetrievingLimitationMemberFromStore, fetchPurposeLimitationsGuest, fetchPurposeLimitationsMember, getCookiePurposeLimitationsValuesToSet, getFullPurposeLimitationsForStorePayload, getIsExpirationTimeExpired, getLevelsFromLimitations, transformLimitations, updatePurposeLimitationsGuest, updatePurposeLimitationsMember, } from '../../services/purposeLimitations';
import getPurposeLimitationsMemberState from '../selectors/getPurposeLimitationsMemberState';
import getPurposeLimitationsGuestState from '../selectors/getPurposeLimitationsGuestState';
import { PURPOSE_LIMITATIONS_ACTION_TYPES, COOKIE_PURPOSE_LIMITATIONS_EXPIRATION_PERIOD, } from '../constants';
import { store } from '..';
const getPurposeLimitationsMemberAction = ({ accessToken, userId, userExperience, }) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_MEMBER_PENDING,
            });
            const { purposeLimitationsToken, purposeLimitations, purposeLimitationsLevels, lastUpdatedTimestamp, userId: storedUserId, } = getPurposeLimitationsMemberState(getState());
            const canRetrieveLimitationFromStore = checkRetrievingLimitationMemberFromStore();
            let responseLimitations;
            if (canRetrieveLimitationFromStore &&
                !getIsExpirationTimeExpired(purposeLimitationsToken.expires) &&
                userId === storedUserId) {
                responseLimitations = {
                    userId,
                    purposeLimitations,
                    purposeLimitationsToken,
                    purposeLimitationsLevels,
                    lastUpdatedTimestamp,
                };
            }
            else {
                const { purposeLimitations: purposeLimitationsServer, ...serverRest } = await fetchPurposeLimitationsMember(accessToken, userExperience);
                const cookiePurposeLimitationsValuesToSet = getCookiePurposeLimitationsValuesToSet(transformLimitations(purposeLimitationsServer));
                Cookies.set('ni_c', cookiePurposeLimitationsValuesToSet, {
                    expires: COOKIE_PURPOSE_LIMITATIONS_EXPIRATION_PERIOD,
                });
                responseLimitations = {
                    userId,
                    purposeLimitations: transformLimitations(purposeLimitationsServer),
                    purposeLimitationsLevels: getLevelsFromLimitations(purposeLimitationsServer),
                    lastUpdatedTimestamp: Date.now(),
                    ...serverRest,
                };
            }
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_MEMBER_SUCCESS,
                payload: responseLimitations,
            });
        }
        catch (error) {
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_MEMBER_FAIL,
                payload: error.message,
            });
        }
    };
};
const getPurposeLimitationsGuestAction = ({ userId, userExperience, location, }) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_GUEST_PENDING,
            });
            const { purposeLimitations, purposeLimitationsLevels, lastUpdatedTimestamp, userId: storedUserId, } = getPurposeLimitationsGuestState(getState());
            let responseLimitations;
            if (checkRetrievingLimitationGuestFromStore() && storedUserId === userId) {
                responseLimitations = {
                    userId,
                    purposeLimitations,
                    purposeLimitationsLevels,
                    lastUpdatedTimestamp,
                };
            }
            else {
                const { purposeLimitations: purposeLimitationsServer, ...serverRest } = await fetchPurposeLimitationsGuest(userExperience, location);
                const cookiePurposeLimitationsValuesToSet = getCookiePurposeLimitationsValuesToSet(transformLimitations(purposeLimitationsServer));
                Cookies.set('ni_c', cookiePurposeLimitationsValuesToSet, {
                    expires: COOKIE_PURPOSE_LIMITATIONS_EXPIRATION_PERIOD,
                });
                responseLimitations = {
                    userId,
                    purposeLimitations: transformLimitations(purposeLimitationsServer),
                    purposeLimitationsLevels: getLevelsFromLimitations(purposeLimitationsServer),
                    lastUpdatedTimestamp: Date.now(),
                    ...serverRest,
                };
            }
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_GUEST_SUCCESS,
                payload: responseLimitations,
            });
        }
        catch (error) {
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.GET_PURPOSE_LIMITATIONS_GUEST_FAIL,
                payload: error.message,
            });
        }
    };
};
const updatePurposeLimitationsMemberAction = (props) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_MEMBER_PENDING,
            });
            const { userId, purposeLimitations: purposeLimitationsState, purposeLimitationsLevels: purposeLimitationsLevelsState, } = getPurposeLimitationsMemberState(store.getState());
            const { purposeLimitations, ...responseRest } = await updatePurposeLimitationsMember(props);
            const purposeLimitationsFull = getFullPurposeLimitationsForStorePayload(transformLimitations(purposeLimitations), purposeLimitationsState);
            const purposeLimitationsLevelsFull = {
                ...purposeLimitationsLevelsState,
                ...getLevelsFromLimitations(purposeLimitations),
            };
            const cookiePurposeLimitationsValuesToSet = getCookiePurposeLimitationsValuesToSet(purposeLimitationsFull);
            Cookies.set('ni_c', cookiePurposeLimitationsValuesToSet, {
                expires: COOKIE_PURPOSE_LIMITATIONS_EXPIRATION_PERIOD,
            });
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_MEMBER_SUCCESS,
                payload: {
                    purposeLimitations: purposeLimitationsFull,
                    purposeLimitationsLevels: purposeLimitationsLevelsFull,
                    userId,
                    lastUpdatedTimestamp: Date.now(),
                    ...responseRest,
                },
            });
        }
        catch (error) {
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_MEMBER_FAIL,
                payload: error.message,
            });
        }
    };
};
const updatePurposeLimitationsGuestAction = (contextFrameToUpdate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_GUEST_PENDING,
            });
            const { userId, purposeLimitationsLevels } = getPurposeLimitationsGuestState(store.getState());
            const updatedPurposeLimitations = updatePurposeLimitationsGuest(contextFrameToUpdate);
            const cookiePurposeLimitationsValuesToSet = getCookiePurposeLimitationsValuesToSet(updatedPurposeLimitations);
            Cookies.set('ni_c', cookiePurposeLimitationsValuesToSet, {
                expires: COOKIE_PURPOSE_LIMITATIONS_EXPIRATION_PERIOD,
            });
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_GUEST_SUCCESS,
                payload: {
                    purposeLimitations: updatedPurposeLimitations,
                    purposeLimitationsLevels,
                    userId,
                    lastUpdatedTimestamp: Date.now(),
                },
            });
        }
        catch (error) {
            return dispatch({
                type: PURPOSE_LIMITATIONS_ACTION_TYPES.UPDATE_PURPOSE_LIMITATIONS_GUEST_FAIL,
                payload: error.message,
            });
        }
    };
};
const resetPurposeLimitationsMemberAction = () => ({
    type: PURPOSE_LIMITATIONS_ACTION_TYPES.RESET_PURPOSE_LIMITATIONS_MEMBER,
});
export { getPurposeLimitationsMemberAction, getPurposeLimitationsGuestAction, updatePurposeLimitationsMemberAction, updatePurposeLimitationsGuestAction, resetPurposeLimitationsMemberAction, };
