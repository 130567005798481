import { CONTEXT_FRAMES_ACTION_TYPES } from '../constants';
export const initialState = {
    guest: {
        contextFrames: [],
        language: '',
        eTag: '',
        lastModified: '',
        loading: false,
        error: null,
    },
    member: {
        contextFrames: [],
        language: '',
        eTag: '',
        lastModified: '',
        loading: false,
        error: null,
    },
};
const contextFramesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_MEMBER_PENDING:
            return {
                ...state,
                member: {
                    ...state.member,
                    loading: true,
                },
            };
        case CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_GUEST_PENDING:
            return {
                ...state,
                guest: {
                    ...state.guest,
                    loading: true,
                },
            };
        case CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_MEMBER_SUCCESS:
            return {
                ...state,
                member: {
                    ...state.member,
                    loading: false,
                    error: null,
                    ...action.payload,
                },
            };
        case CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_GUEST_SUCCESS:
            return {
                ...state,
                guest: {
                    ...state.guest,
                    loading: false,
                    error: null,
                    ...action.payload,
                },
            };
        case CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_MEMBER_FAIL:
            return {
                ...initialState,
                member: {
                    ...initialState.member,
                    error: action.payload,
                },
            };
        case CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_GUEST_FAIL:
            return {
                ...initialState,
                guest: {
                    ...initialState.guest,
                    error: action.payload,
                },
            };
        case CONTEXT_FRAMES_ACTION_TYPES.RESET_CONTEXT_FRAMES_MEMBER:
            return {
                ...state,
                member: {
                    ...initialState.member,
                },
            };
        default:
            return state;
    }
};
export default contextFramesReducer;
