import { camelizeKeys } from 'humps';
import { NOT_REQUIRED_MODAL_CONTEXT_LEVEL } from '@nike/privacy-shared-logic';
import * as API from '../API';
import { privacyCookieModalSeenKey } from '../constants';
import getContextFramesMemberState from '../store/selectors/getContextFramesMemberState';
import getContextFramesGuestState from '../store/selectors/getContextFramesGuestState';
import { store } from '../store';
export const getContextFrameByName = (contextFrames, name) => contextFrames.find(({ metadata }) => metadata.contextFrameName === name);
export const getContextFramesLevel = (contextFrames, purposeLimitationsLevels) => {
    const levels = contextFrames.reduce((levels, contextFrame) => {
        const level = purposeLimitationsLevels[contextFrame.purposeLimitationCategory];
        if (typeof level === 'undefined') {
            throw new Error(`Level for "${contextFrame.purposeLimitationCategory}" category was not found`);
        }
        levels.push(level);
        return levels;
    }, []);
    return Math.max(...levels);
};
const getAlwaysOnDisclosure = (alwaysOnDisclosureCamelized, level) => {
    if (!alwaysOnDisclosureCamelized)
        return {};
    return {
        alwaysOnDisclosure: {
            ...alwaysOnDisclosureCamelized,
            level,
        },
    };
};
export const transformContextFrames = ({ contextFrames, purposeLimitationsLevels, }) => contextFrames.map(contextFrame => {
    const getAlwaysOnDisclosureLevel = getContextFramesLevel(contextFrame.contexts, purposeLimitationsLevels);
    const contextFrameWithLevel = {
        ...contextFrame,
        metadata: {
            ...contextFrame.metadata,
            ...getAlwaysOnDisclosure(contextFrame.metadata.alwaysOnDisclosure, getAlwaysOnDisclosureLevel),
        },
        contexts: contextFrame.contexts.map(context => ({
            ...context,
            level: purposeLimitationsLevels[context.purposeLimitationCategory],
        })),
    };
    return contextFrameWithLevel;
});
const compareGuestAndMemberPromptRequired = ({ contextFrame, memberPurposeLimitations, guestPurposeLimitations, }) => {
    const isModalSeen = window.localStorage.getItem(privacyCookieModalSeenKey);
    if (isModalSeen === 'true')
        return false;
    const purposeLimitationsDifferent = contextFrame.contexts.some(context => {
        if (context.level <= NOT_REQUIRED_MODAL_CONTEXT_LEVEL)
            return false;
        const relatedMemberPurposeLimitation = memberPurposeLimitations.find(({ category }) => category === context.purposeLimitationCategory);
        const relatedGuestPurposeLimitation = guestPurposeLimitations.find(({ category }) => category === context.purposeLimitationCategory);
        if (!relatedGuestPurposeLimitation)
            return false;
        if (!relatedMemberPurposeLimitation)
            return false;
        if (relatedGuestPurposeLimitation.isDefault)
            return false;
        return relatedMemberPurposeLimitation.isEnabled !== relatedGuestPurposeLimitation.isEnabled;
    });
    if (!purposeLimitationsDifferent) {
        window.localStorage.setItem(privacyCookieModalSeenKey, 'true');
    }
    return purposeLimitationsDifferent;
};
export const transformContextFramesMemberPrompt = ({ contextFrames, memberPurposeLimitations, guestPurposeLimitations, }) => {
    if (!guestPurposeLimitations.length)
        return contextFrames;
    return contextFrames.map(contextFrame => ({
        ...contextFrame,
        isGuestAndMemberSelectionMismatched: compareGuestAndMemberPromptRequired({
            contextFrame,
            memberPurposeLimitations,
            guestPurposeLimitations,
        }),
    }));
};
export const getContextFramesMember = async (language, userExperience) => {
    const { eTag: eTagStored, lastModified: lastModifiedStored, } = getContextFramesMemberState(store.getState());
    const response = await API.getContextFramesRequest({
        language,
        userExperience,
        eTag: eTagStored,
        lastModified: lastModifiedStored,
    });
    if (response.status !== 200) {
        throw new Error(`${response.status} ${response.statusText}`);
    }
    const etagFromResponse = response.headers.get('ETag') || undefined;
    const lastModifiedFromResponse = response.headers.get('Last-Modified') || undefined;
    const contextFramesFromServer = await response.json();
    const camelizedFrames = camelizeKeys(contextFramesFromServer);
    return { ...camelizedFrames, eTag: etagFromResponse, lastModified: lastModifiedFromResponse };
};
export const getContextFramesGuest = async (language, userExperience) => {
    const { eTag: eTagStored, lastModified: lastModifiedStored, } = getContextFramesGuestState(store.getState());
    const response = await API.getContextFramesRequest({
        language,
        userExperience,
        eTag: eTagStored,
        lastModified: lastModifiedStored,
    });
    if (response.status !== 200) {
        throw new Error(`${response.status} ${response.statusText}`);
    }
    const etagFromResponse = response.headers.get('ETag') || undefined;
    const lastModifiedFromResponse = response.headers.get('Last-Modified') || undefined;
    const contextFramesFromServer = await response.json();
    const camelizedFrames = camelizeKeys(contextFramesFromServer);
    return { ...camelizedFrames, eTag: etagFromResponse, lastModified: lastModifiedFromResponse };
};
