const getContextFramesRequest = async ({ language, userExperience, eTag, lastModified, }) => {
    const url = new URL(`${process.env.PRIVACY_API_BASE_URL}/context_frames/v2/experience/${userExperience}/language/${language}`);
    const request = new Request(url.toString(), {
        headers: {
            ...(eTag &&
                lastModified &&
                {}),
        },
    });
    return fetch(request);
};
const getPurposeLimitationsMember = async (accessToken, userExperience) => {
    const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
    });
    const url = new URL(`${process.env.PRIVACY_API_BASE_URL}/purpose_limitations/v1`);
    url.search = new URLSearchParams({
        experience: userExperience,
    }).toString();
    const request = new Request(url.toString(), { headers });
    return fetch(request);
};
const getPurposeLimitationsGuest = async (userExperience, location) => {
    const url = new URL(`${process.env.PRIVACY_API_BASE_URL}/purpose_limitation_defaults/v1/experience/${userExperience}/location/${location}`);
    return fetch(new Request(url.toString()));
};
const updatePurposeLimitations = async ({ accessToken, userExperience, contextFrameToUpdateBody, contextFrameId, }) => {
    const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
    });
    const url = new URL(`${process.env.PRIVACY_API_BASE_URL}/consent/v2/context_frame/${contextFrameId}`);
    url.search = new URLSearchParams({
        experience: userExperience,
    }).toString();
    const request = new Request(url.toString(), {
        body: JSON.stringify(contextFrameToUpdateBody),
        headers,
        method: 'PUT',
    });
    return fetch(request);
};
export { getContextFramesRequest, getPurposeLimitationsMember, getPurposeLimitationsGuest, updatePurposeLimitations, };
