import { getContextFramesMember, getContextFramesGuest, transformContextFrames, transformContextFramesMemberPrompt, } from '../../services/contextFrames';
import { CONTEXT_FRAMES_ACTION_TYPES, } from '../constants';
import getPurposeLimitationsMemberState from '../selectors/getPurposeLimitationsMemberState';
import getPurposeLimitationsGuestState from '../selectors/getPurposeLimitationsGuestState';
const getContextFramesMemberAction = ({ language, userExperience, }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_MEMBER_PENDING,
        });
        const response = await getContextFramesMember(language, userExperience);
        const { purposeLimitations: memberPurposeLimitations, purposeLimitationsLevels: memberPurposeLimitationsLevels, } = getPurposeLimitationsMemberState(getState());
        const guestPurposeLimitations = getPurposeLimitationsGuestState(getState()).purposeLimitations;
        const contextFramesTransformed = transformContextFrames({
            contextFrames: response.contextFrames,
            purposeLimitationsLevels: memberPurposeLimitationsLevels,
        });
        return dispatch({
            type: CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_MEMBER_SUCCESS,
            payload: {
                contextFrames: transformContextFramesMemberPrompt({
                    contextFrames: contextFramesTransformed,
                    memberPurposeLimitations,
                    guestPurposeLimitations,
                }),
                language: response.language,
                eTag: response.eTag,
                lastModified: response.lastModified,
            },
        });
    }
    catch (error) {
        return dispatch({
            type: CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_MEMBER_FAIL,
            payload: error.message,
        });
    }
};
const getContextFramesGuestAction = ({ language, userExperience, }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_GUEST_PENDING,
        });
        const response = await getContextFramesGuest(language, userExperience);
        const { purposeLimitationsLevels } = getPurposeLimitationsGuestState(getState());
        const contextFramesTransformed = transformContextFrames({
            contextFrames: response.contextFrames,
            purposeLimitationsLevels,
        });
        return dispatch({
            type: CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_GUEST_SUCCESS,
            payload: {
                contextFrames: contextFramesTransformed,
                language: response.language,
                eTag: response.eTag,
                lastModified: response.lastModified,
            },
        });
    }
    catch (error) {
        return dispatch({
            type: CONTEXT_FRAMES_ACTION_TYPES.GET_CONTEXT_FRAMES_GUEST_FAIL,
            payload: error.message,
        });
    }
};
const resetContextFramesMemberAction = () => ({
    type: CONTEXT_FRAMES_ACTION_TYPES.RESET_CONTEXT_FRAMES_MEMBER,
});
export { getContextFramesGuestAction, getContextFramesMemberAction, resetContextFramesMemberAction, };
